import React, { FC, useState, useContext, useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { arrivals } from "../lib/api/arrival";
import { arrivaldetails } from "../lib/api/arrivaldetail";

import { ArrivalsGrid } from "./arrivals/arrivalgrid";
import ArrivalsDetail from "./arrivalsdetail/arrivalsdetail";

import Confirmation from "../lib/components/confirmation";
import { DialogInformation } from "../lib/components/dialoginformation";
import { handlePrint } from "../reports/arrivalPDF";

import { SnackContext } from "../lib/context/SnackContext";
import { GenerateErrorMessage } from "../lib/helpers/string_methods";
import { arrivaldataGroupedContainer } from "../lib/api/arrivaldata";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1) * 2,
    },
  });

type ArrivalProps = {} & WithStyles<typeof styles>;

type arrivaltype = {
  containernumber: string;
  producerid: string;
  salesref: string;
  comments: string;
  fotos: any;
  print_date?: any;
  status: number;
  claim: boolean;
};

let currentIntakeData = undefined;
let comments = undefined;
let pics = undefined;

const ArrivalUnstyled: FC<ArrivalProps> = (props) => {
  const { classes } = props;
  const { updateSnack } = useContext(SnackContext);

  const [editingReference, setEditingReference] = useState(undefined); //{ container: "APRU5080197", farmnumber: "D0751", arrival_id: 1 });
  const [currentData, setCurrentData] = useState<arrivaltype>({
    containernumber: "",
    producerid: "",
    salesref: "",
    comments: "",
    fotos: "",
    status: 0,
    claim: false,
  });

  const [isDirty, setIsDirty] = useState(false);
  const [confirmDirty, setConfirmDirty] = useState(false);
  const [error, setError] = useState<string>(undefined);
  const [removeID, setRemoveID] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    loadArrivalData();
  }, []);

  const loadArrivalData = async () => {
    setLoading(true);
    try {
      const data = await arrivaldataGroupedContainer();
      setData(data);
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error retrieving data");
      updateSnack({ show: true, color: "red", message: err });
    }
    setLoading(false);
  };

  const handleEdit = async (container: string, producerid: string, salesref: string, arrival_id: number, status: number, claim: boolean) => {
    comments = undefined;
    pics = undefined;

    const arrivalData: arrivaltype = {
      containernumber: container,
      producerid: producerid,
      salesref: salesref,
      comments: undefined,
      fotos: undefined,
      status: status,
      claim: claim,
    };

    setConfirmDirty(false);
    setIsDirty(false);
    setCurrentData(arrivalData);
    setEditingReference({
      container: container,
      producerid: producerid,
      salesref: salesref,
      arrival_id: arrival_id,
    });
  };

  const handleEditClose = () => {
    if (isDirty) {
      setConfirmDirty(true);
    } else {
      setEditingReference(undefined);
    }
  };

  const handleEditConfirm = async (exportRequested: boolean = false, status: number = 1, claim = currentData.claim) => {
    let arrivalId = editingReference.arrival_id;

    const currentDataNew = { ...currentData, comments: comments, fotos: pics, claim, status };

    if (exportRequested && !currentDataNew.print_date) {
      currentDataNew.print_date = new Date();
    }

    if (!arrivalId) {
      const result = await arrivals.create({ data: currentDataNew });
      arrivalId = result[0];
    } else {
      await arrivals.update(editingReference.arrival_id, { data: currentDataNew });
    }

    const exportData = { ...editingReference, arrival_id: arrivalId };

    await handleEditConfirmDetails(arrivalId);
    setConfirmDirty(false);
    setIsDirty(false);
    loadArrivalData();
    setEditingReference(undefined);

    if (exportRequested) {
      return exportData;
    }
  };

  const handleEditConfirmDetails = async (arrivalid) => {
    if (!currentIntakeData) {
      return;
    }
    new Promise(async (resolve, reject) => {
      currentIntakeData.map((data) => {
        const newData = {
          arrival_id: arrivalid,
          barcode_id: data.barcode_id,
          variety: data.varietycode,
          grade: data.gradecode,
          count: data.countcode,
          brand: data.markcode,
          pickingref: data.pickingref,
          qualityscore: data.qualityscore && (data.qualityscore || "").toString().trim() != "" ? data.qualityscore : 0,
          sequencenumber: data.sequencenumber,
        };
        if (data.arrivaldetail_id == 0) {
          arrivaldetails.create({ data: newData }).then(() => {
            resolve("created");
          });
        } else {
          arrivaldetails.update(data.arrivaldetail_id, { data: newData }).then(() => {
            resolve("updated");
          });
        }
      });
    })
      .then(() => {
        return;
      })
      .catch((error) => {
        const err = GenerateErrorMessage(error, "Error updating data");
        updateSnack({ show: true, color: "red", message: err });
      });
  };

  const handleExportExcel = async () => {
    const data = await handleEditConfirm(true);
    window.location.href = `/api/arrival/ext/exportArrival/` + `${data.arrival_id}/` + `${data.container ? data.container : "0"}/` + `${data.producerid}/` + `${data.salesref}`;
  };

  const handleExportPDF = async () => {
    const newEditingRef = await handleEditConfirm(true);
    handlePrint(newEditingRef.arrival_id, newEditingRef.container, newEditingRef.producerid, newEditingRef.salesref);
  };

  const handleConfirmContinueClose = () => {
    setConfirmDirty(false);
  };

  const handleConfirmContinueConfirm = async () => {
    setConfirmDirty(false);
    setIsDirty(false);
    setEditingReference(undefined);
  };

  const handleErrorClose = () => {
    setError(undefined);
  };

  const handleChange = async (property: string, value: any) => {
    if (property == "gridload") {
      currentIntakeData = value;
    } else if (property == "fotosload") {
      pics = JSON.stringify(value);
    } else {
      if (!isDirty) {
        setIsDirty(true);
      }
      if (property == "grid") {
        currentIntakeData = value;
      } else if (property == "fotos") {
        pics = JSON.stringify(value);
      } else if (property == "comments") {
        comments = value;
      }
    }
  };

  const handleRemove = (arrival_id: number) => {
    setRemoveID(arrival_id);
  };

  const handleRemoveClose = () => {
    setRemoveID(undefined);
  };

  const handleRemoveConfirm = async () => {
    await arrivals.remove(removeID);
    setRemoveID(undefined);
    loadArrivalData();
  };

  return (
    <div className={classes.root}>
      <ArrivalsGrid handleEdit={handleEdit} handleRemove={handleRemove} loading={loading} data={data} handleReload={() => loadArrivalData()} />
      {error && <DialogInformation isOpen={true} handleClose={handleErrorClose} handleOK={handleErrorClose} title={"Error!"} body={`${error}`} showinput={false} />}
      {confirmDirty && (
        <Confirmation
          isOpen={editingReference ? true : false}
          handleClose={handleConfirmContinueClose}
          handleConfirm={handleConfirmContinueConfirm}
          title={"Unsaved Changes"}
          body={`You have unsaved data. Do you want to continue?`}
        />
      )}
      {removeID && (
        <Confirmation
          isOpen={removeID ? true : false}
          handleClose={handleRemoveClose}
          handleConfirm={handleRemoveConfirm}
          title={"Remove Record"}
          body={`Are you sure you want to remove any data processed for the selected arrival?`}
        />
      )}
      {editingReference && (
        <Confirmation isOpen={editingReference ? true : false} handleClose={handleEditClose} handleConfirm={() => {}} title={"QC Arrival"} body={undefined}>
          <ArrivalsDetail
            container={editingReference.container}
            producerid={editingReference.producerid}
            salesref={editingReference.salesref}
            arrival_id={editingReference.arrival_id}
            handleClose={handleEditClose}
            handleSubmit={handleEditConfirm}
            handleExportExcel={handleExportExcel}
            handleExportPDF={handleExportPDF}
            handleChange={handleChange}
            status={currentData.status}
            claim={currentData.claim}
          />
        </Confirmation>
      )}
    </div>
  );
};

export default withStyles(styles)(ArrivalUnstyled);
